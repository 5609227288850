import {
  Box,
  Menu as ChakraMenu,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  List,
  ListItem,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useCookies } from "react-cookie";
import { Facebook, Instagram, Mail } from "react-feather";
import { useTranslation as useTranslationi18n } from "next-i18next";

import Menu from "@/components/Layout/Menu";
import ButtonAnimated from "@/components/common/ButtonAnimated";
import checkIfFullFridge from "@/utils/fullFridge";
import { getFlagShort, languagesShort } from "@/utils/language";
import StickyHeader from "./StickyHeader";

export default function Layout({
  hideFunctionality = true,
  hideFooter = false,
  children,
}) {
  const { pageRef, isSticky } = StickyHeader();
  const { publicRuntimeConfig } = getConfig();
  const { t } = useTranslation();
  const { i18n } = useTranslationi18n();
  const router = useRouter();
  const { data: session, status } = useSession();
  const [cookie, setCookie] = useCookies(["NEXT_LOCALE"]);
  const { pathname, locale, asPath } = router;
  const isActive = (path: string) =>
    pathname === path || (pathname.startsWith(path) && !path.endsWith("/"));

  const languages = useMemo(
    () =>
      languagesShort.reduce(
        (acc, curr) => (
          (acc[curr] = {
            icon: getFlagShort(curr),
          }),
          acc
        ),
        {}
      ),
    []
  );
  const currentLanguage = languages[locale];

  const switchLanguage = (locale) => async () => {
    if (cookie.NEXT_LOCALE !== locale)
      setCookie("NEXT_LOCALE", locale, { path: "/" });

    router.push(asPath, asPath, { locale });
  };

  const [isLargerThan1500] = useMediaQuery("(min-width: 1500px)");

  return (
    <>
      <Box
        bg={checkIfFullFridge() ? "green.500" : "red.500"}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1001}
      >
        <Flex
          justify="space-between"
          align="center"
          maxW={isLargerThan1500 ? "1100px" : "975px"}
          mx="auto"
          pl={{ base: "15px", sm: "50px", xl: 0 }}
          pr={{ base: "15px", sm: "50px", xl: 0 }}
          pt="10px"
          pb="10px"
          minH={{ base: "66px", sm: "78px" }}
        >
          <Box
            bg="white"
            borderRadius="10px"
            py="8px"
            px="10px"
            onClick={() => router.push("/")}
            cursor="pointer"
          >
            <Image
              src={
                checkIfFullFridge()
                  ? `${publicRuntimeConfig.basePath}/logo-food.svg`
                  : `${publicRuntimeConfig.basePath}/logo.svg`
              }
              alt=""
              w={{ base: "140px", sm: "196px" }}
            />
          </Box>
          <Flex align="center">
            {!hideFunctionality && (
              <>
                <Stack direction="row" mr="50px">
                  <Box
                    role="group"
                    color="white"
                    textTransform="uppercase"
                    letterSpacing="1px"
                    px="15px"
                    onClick={() => router.push("/")}
                    cursor="pointer"
                  >
                    Szukam
                    <Box
                      h="3px"
                      borderRadius="3px"
                      mx="auto"
                      mt="3px"
                      bg="white"
                      opacity={isActive("/") || isActive("/p") ? 0.5 : 0}
                      w={isActive("/") || isActive("/p") ? "100%" : "1px"}
                      transition="all .25s ease-in-out"
                      _groupHover={{ opacity: 0.5, w: "100%" }}
                    ></Box>
                  </Box>
                  <Box
                    role="group"
                    color="white"
                    textTransform="uppercase"
                    letterSpacing="1px"
                    px="15px"
                    onClick={() => router.push("/add-product")}
                    cursor="pointer"
                  >
                    Sprzedaję
                    <Box
                      h="3px"
                      borderRadius="3px"
                      mx="auto"
                      mt="5px"
                      bg="white"
                      opacity={isActive("/add-product") ? 0.5 : 0}
                      w={isActive("/add-product") ? "100%" : "1px"}
                      transition="all .25s ease-in-out"
                      _groupHover={{ opacity: 0.5, w: "100%" }}
                    ></Box>
                  </Box>
                  <Box
                    role="group"
                    color="white"
                    textTransform="uppercase"
                    letterSpacing="1px"
                    px="15px"
                    onClick={() => router.push("/chat")}
                    cursor="pointer"
                  >
                    Czat
                    <Box
                      h="3px"
                      borderRadius="3px"
                      mx="auto"
                      mt="5px"
                      bg="white"
                      opacity={isActive("/chat") ? 0.5 : 0}
                      w={isActive("/chat") ? "100%" : "1px"}
                      transition="all .25s ease-in-out"
                      _groupHover={{ opacity: 0.5, w: "100%" }}
                    ></Box>
                  </Box>
                </Stack>
                <ButtonAnimated
                  text={
                    status === "authenticated" && session?.user?.garages[0]?.id
                      ? t("myGarage")
                      : t("rentGarage")
                  }
                  bg="red.600"
                  _hover={{ bg: "red.700" }}
                  onClick={() => router.push(`/garage`)}
                />
              </>
            )}

            <Flex gap={{ base: 0, sm: "10px" }} align="center">
              {!checkIfFullFridge() && <Menu />}
              <ChakraMenu>
                <MenuButton as="a">
                  <Image
                    src={`${publicRuntimeConfig.basePath}/${currentLanguage?.icon}`}
                    alt=""
                    w="25px"
                    d="block"
                    m="0.4rem 0.8rem"
                  />
                </MenuButton>
                <MenuList minW={0}>
                  {Object.keys(languages).map((key) => (
                    <MenuItem
                      key={key}
                      onClick={switchLanguage(key)}
                      isDisabled={key === locale}
                    >
                      <Image
                        src={`${publicRuntimeConfig.basePath}/${languages[key]?.icon}`}
                        alt=""
                        w="25px"
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </ChakraMenu>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box ref={pageRef}>{children}</Box>
      {!hideFooter && (
        <Box py={{ base: "30px", sm: "50px", xl: "100px" }} bg="gray.200">
          <Box
            maxW={isLargerThan1500 ? "1100px" : "975px"}
            px={{ base: "15px", sm: "50px", xl: 0 }}
            mx="auto"
          >
            <Flex
              gap="40px"
              justify="space-between"
              flexWrap={{ base: "wrap", xl: "nowrap" }}
            >
              {/*!hideFunctionality && (
                <Box>
                  <Heading
                    size="md"
                    variant="gray"
                    mt={0}
                    fontSize={{ base: "18px", sm: "20px" }}
                  >
                    Kategorie
                  </Heading>
                  <List spacing="5px">
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Dom i ogród</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Moda</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Dziecko</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Sport</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Kultura i rozrywka</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Elektronika</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Sztuka</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Uroda</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Motoryzacja</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Nieruchomości</Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckCircle}
                        w="15px"
                        h="15px"
                        color="gray.500"
                        verticalAlign="-2px"
                      />
                      <Link>Jedzenie</Link>
                    </ListItem>
                  </List>
                </Box>
              )*/}
              <Box>
                <Heading
                  size="md"
                  variant="gray"
                  mt={0}
                  fontSize={{ base: "18px", sm: "20px" }}
                >
                  {t("home.informationForYou")}
                </Heading>
                <Flex gap="40px">
                  <List spacing="5px">
                    <ListItem>
                      <Link
                        href={`${publicRuntimeConfig.basePath}/aplikacja-mobilna`}
                      >
                        {t("home.mobileApp")}
                      </Link>
                    </ListItem>
                    {!checkIfFullFridge() && (
                      <ListItem>
                        <Link
                          href={`${publicRuntimeConfig.basePath}/terms-and-conditions`}
                        >
                          {t("regulations")}
                        </Link>
                      </ListItem>
                    )}
                    {!checkIfFullFridge() && (
                      <ListItem>
                        <Link
                          href={`${publicRuntimeConfig.basePath}/privacy-policy`}
                        >
                          {t("privacyPolicy")}
                        </Link>
                      </ListItem>
                    )}
                    <ListItem>
                      <Link
                        href={
                          checkIfFullFridge()
                            ? `${publicRuntimeConfig.basePath}/terms-and-conditions`
                            : "https://fullfridge.app/terms-and-conditions"
                        }
                      >
                        {!checkIfFullFridge()
                          ? t("regulationsFullFridge")
                          : t("regulations")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href={
                          checkIfFullFridge()
                            ? `${publicRuntimeConfig.basePath}/privacy-policy`
                            : "https://fullfridge.app/privacy-policy"
                        }
                      >
                        {!checkIfFullFridge()
                          ? t("privacyPolicyFullFridge")
                          : t("privacyPolicy")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.contact")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.safetyRegulations")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.advertising")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.pressOffice")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.cookiesPolicy")}
                      </Link>
                    </ListItem>
                  </List>
                  <List spacing="5px">
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.howWorks")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.prices")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.aboutUs")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.taxesInfo")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.siteMap")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.forBuyers")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.forSellers")}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={`${publicRuntimeConfig.basePath}/`}>
                        {t("home.locationSharing")}
                      </Link>
                    </ListItem>
                  </List>
                </Flex>
              </Box>
              <Box>
                <Heading
                  size="md"
                  variant="gray"
                  mt={0}
                  fontSize={{ base: "18px", sm: "20px" }}
                >
                  {t("home.joinUs")}
                </Heading>
                <Stack direction="row" spacing="10px">
                  <Link
                    href="https://www.facebook.com/profile.php?id=100090055680596"
                    target="_blank"
                    lineHeight={0}
                  >
                    <Icon
                      as={Facebook}
                      w={{ base: "30px", sm: "50px" }}
                      h={{ base: "30px", sm: "50px" }}
                    />
                  </Link>
                  <Link
                    href="https://www.instagram.com/garazowewyprzedaze/"
                    target="_blank"
                    lineHeight={0}
                  >
                    <Icon
                      as={Instagram}
                      w={{ base: "30px", sm: "50px" }}
                      h={{ base: "30px", sm: "50px" }}
                    />
                  </Link>
                </Stack>
                <Heading
                  mt={{ base: "40px", sm: "100px" }}
                  size="md"
                  variant="gray"
                  fontSize={{ base: "18px", sm: "20px" }}
                >
                  {t("home.contact")}
                </Heading>
                <Stack direction="row" spacing="10px" alignItems="center">
                  <Icon as={Mail} w="25px" h="25px" color="gray.500" />
                  <Link
                    fontSize="18px"
                    href={
                      !checkIfFullFridge()
                        ? "mailto:kontakt@garazowewyprzedaze.pl"
                        : "mailto:office@fullfridge.app"
                    }
                  >
                    {!checkIfFullFridge()
                      ? "kontakt@garazowewyprzedaze.pl"
                      : "office@fullfridge.app"}
                  </Link>
                </Stack>
              </Box>
              <Box>
                <Heading
                  size="md"
                  variant="gray"
                  mt={0}
                  fontSize={{ base: "18px", sm: "20px" }}
                >
                  {t("home.freeApp")}
                </Heading>
                <SimpleGrid
                  columns={{ base: 1, sm: 2 }}
                  spacing={{ base: "30px", sm: "40px" }}
                >
                  <Flex justify={{ base: "center", sm: "flex-end" }}>
                    <Box w="210px">
                      <a
                        href={
                          !checkIfFullFridge()
                            ? "https://garazowewyprzedaze.pl/download/android"
                            : "https://fullfridge.app/download/android"
                        }
                        rel="noopener"
                      >
                        <Image
                          src={
                            i18n?.language == "pl"
                              ? `${publicRuntimeConfig.basePath}/google-play.png`
                              : `${publicRuntimeConfig.basePath}/google-play-en.png`
                          }
                          alt=""
                          d="block"
                          mx="auto"
                          w={{ base: "150px", sm: "205px" }}
                        />
                      </a>
                      <Image
                        src={`${publicRuntimeConfig.basePath}/${
                          checkIfFullFridge() ? "ff" : "gs"
                        }_qr_android.svg`}
                        alt=""
                        w={{ base: "160px", sm: "100%" }}
                        d="block"
                        mx="auto"
                        mt="27.5px"
                      />
                    </Box>
                  </Flex>
                  <Flex justify={{ base: "center", sm: "flex-start" }}>
                    <Box w="210px">
                      <a
                        href={
                          !checkIfFullFridge()
                            ? "https://garazowewyprzedaze.pl/download/ios"
                            : "https://fullfridge.app/download/ios"
                        }
                        rel="noopener"
                      >
                        <Image
                          src={
                            i18n?.language == "pl"
                              ? `${publicRuntimeConfig.basePath}/app-store.png`
                              : `${publicRuntimeConfig.basePath}/app-store-en.png`
                          }
                          alt=""
                          d="block"
                          mx="auto"
                          w={{ base: "150px", sm: "205px" }}
                        />
                      </a>
                      <Image
                        src={`${publicRuntimeConfig.basePath}/${
                          checkIfFullFridge() ? "ff" : "gs"
                        }_qr_ios.svg`}
                        alt=""
                        w={{ base: "160px", sm: "100%" }}
                        d="block"
                        mx="auto"
                        mt="20px"
                      />
                    </Box>
                  </Flex>
                </SimpleGrid>
              </Box>
            </Flex>
            <Flex
              gap={{ base: "10px", sm: "40px" }}
              mt="50px"
              direction={{ base: "column", sm: "row" }}
              align={{ base: "center", md: "flex-start" }}
            >
              <Box
                as="a"
                d="block"
                href="https://copernicusapps.com/"
                target="_blank"
                w="130px"
              >
                <Image
                  src={`${publicRuntimeConfig.basePath}/logo-copernicus.svg`}
                  alt=""
                  w="100%"
                />
              </Box>
              <Text
                fontSize="12px"
                color="gray.600"
                flex={1}
                textAlign={{ base: "center", sm: "justify" }}
              >
                {t("copernicusInfo")}
              </Text>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
}
